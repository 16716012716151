// TODO: move this. This is data used on the site, not mock data

export const on3Data = [
  {
    imgSrc: 'https://on3static.com/static/on3/on3-red.jpeg',
    twitterHandle: 'on3sports',
    instagramHandle: 'on3',
    youtubeHandle: 'On3sports',
    youtubeHandle2: 'On3Roundtable',
  },
  {
    imgSrc: 'https://on3static.com/static/on3/on3-square-recruits.png',
    twitterHandle: 'on3recruits',
    instagramHandle: 'on3recruits',
    youtubeHandle: 'on3recruits',
  },
];

export const niluData = [
  {
    imgSrc: 'https://on3static.com/static/on3/on3-nil-social.png',
    twitterHandle: 'on3nil',
    twitterHandle2: 'deals_nil',
    instagramHandle: 'on3nil',
  },
];

export const transferPortalData = [
  {
    imgSrc: 'https://on3static.com/static/on3/on3-transfer-portal.png',
    twitterHandle: 'on3sports',
    instagramHandle: 'transferportal',
  },
];
