import { SocialIcon } from '@on3/ui-lib/components/SocialIcon';
import { Email } from 'components/Svg/Email';
import { SideModuleWrapper } from 'components/UI/Layout/Containers/Sidebar/SideModuleWrapper';
import { TextLink } from 'components/UI/Link/TextLink';

import styles from './Connect.module.scss';

interface IConnectListProps {
  title: string;
  list: IConnectProps[];
}
interface IConnectProps {
  imgSrc: string;
  twitterHandle?: string;
  twitterHandle2?: string;
  instagramHandle?: string;
  youtubeHandle?: string;
  youtubeHandle2?: string;
  email?: string;
}

export const Connect = ({ list, title }: IConnectListProps) => {
  return (
    <SideModuleWrapper title={title}>
      {list.map((item, index) => {
        const {
          imgSrc,
          twitterHandle,
          twitterHandle2,
          instagramHandle,
          email,
          youtubeHandle,
          youtubeHandle2,
        } = item;

        return (
          <div className={styles.social} key={index}>
            <img
              alt="On3 Logo"
              className={styles.image}
              height={50}
              loading="lazy"
              src={`${imgSrc}?v=2`}
              width={50}
            />
            <ul>
              {twitterHandle && (
                <li className={styles.socialitem}>
                  <SocialIcon type="twitter" />
                  <TextLink
                    className={styles.link}
                    href={`https://twitter.com/${twitterHandle}`}
                    rel="noopener noreferrer"
                    target="_blank"
                    underline="none"
                  >
                    {twitterHandle}
                  </TextLink>
                </li>
              )}
              {twitterHandle2 && (
                <li className={styles.socialitem}>
                  <SocialIcon type="twitter" />
                  <TextLink
                    className={styles.link}
                    href={`https://twitter.com/${twitterHandle2}`}
                    rel="noopener noreferrer"
                    target="_blank"
                    underline="none"
                  >
                    {twitterHandle2}
                  </TextLink>
                </li>
              )}
              {instagramHandle && (
                <li className={styles.socialitem}>
                  <SocialIcon type="instagram" />
                  <TextLink
                    className={styles.link}
                    href={`https://www.instagram.com/${instagramHandle}/`}
                    rel="noopener noreferrer"
                    target="_blank"
                    underline="none"
                  >
                    {instagramHandle}
                  </TextLink>
                </li>
              )}
              {youtubeHandle && (
                <li className={styles.socialitem}>
                  <SocialIcon type="youtube" />
                  <TextLink
                    className={styles.link}
                    href={`https://www.youtube.com/@${youtubeHandle}`}
                    rel="noopener noreferrer"
                    target="_blank"
                    underline="none"
                  >
                    {youtubeHandle}
                  </TextLink>
                </li>
              )}
              {youtubeHandle2 && (
                <li className={styles.socialitem}>
                  <SocialIcon type="youtube" />
                  <TextLink
                    className={styles.link}
                    href={`https://www.youtube.com/@${youtubeHandle2}`}
                    rel="noopener noreferrer"
                    target="_blank"
                    underline="none"
                  >
                    {youtubeHandle2}
                  </TextLink>
                </li>
              )}
              {email && (
                <li className={styles.socialitem}>
                  <Email />
                  <TextLink
                    className={styles.link}
                    href={`mailto:${email}`}
                    rel="noopener noreferrer"
                    target="_blank"
                    underline="none"
                  >
                    {email}
                  </TextLink>
                </li>
              )}
            </ul>
          </div>
        );
      })}
    </SideModuleWrapper>
  );
};
