import {
  CategoryTypes,
  rdbV2LatestList,
  rdbV2Nil100List,
  v1CategoriesHeadlinesDetail2,
  v1SitesForumsLatestPostsDetail,
  v1SitesHeadlinesForCategoryTypeDetail,
  type V1SitesHeadlinesForCategoryTypeDetailParams,
  v1SitesPremiumHeadlinesList,
  v2OrganizationsScheduleDetail,
  type V2OrganizationsScheduleDetailParams,
} from '@on3/api';
import withAuthentication from '@on3/ui-lib/src/hocs/withAuthentication';
import withValidUrl from '@on3/ui-lib/src/hocs/withValidUrl';
import { getFulfilledValue, proxyApi } from '@on3/ui-lib/utils/api';
import { getAssetUrl } from '@on3/ui-lib/utils/images';
import { ChannelHome, IChannelHomeProps } from 'components/Home/ChannelHome';
import { PageWrapper } from 'components/Layouts/PageWrapper';
import type { GetServerSideProps } from 'next';
import { GraphSchema } from 'store/schemas/Graph';
import { OrganizationSchema } from 'store/schemas/Organization';
import { createCanonicalUrl } from 'utils/meta';

export const Home = ({ siteData, ...props }: IChannelHomeProps) => {
  const { currentSite } = siteData || {};
  const { organization, url: siteUrl } = currentSite || {};
  const title = `${organization?.name} ${organization?.mascot} Football, Basketball & Recruiting`;
  const description = `The latest ${organization?.name} ${organization?.mascot} news, recruiting, transfers, and NIL information from on3.com`;

  const canonical = createCanonicalUrl(siteUrl as string);

  const schemaItems = [
    new OrganizationSchema().toJSON(),
    new OrganizationSchema({
      name: organization?.name || '',
      url: canonical,
      logo: {
        url: getAssetUrl(organization?.defaultAssetRes) || '',
        width: organization?.defaultAssetRes?.width || 0,
        height: organization?.defaultAssetRes?.height || 0,
      },
    }),
  ];

  const schema = new GraphSchema({
    items: schemaItems,
  });

  return (
    <PageWrapper
      {...props}
      canonical={canonical}
      description={description}
      schema={schema}
      siteData={siteData}
      title={title}
    >
      <ChannelHome {...props} />
    </PageWrapper>
  );
};

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const siteData = await withValidUrl.isValid(ctx);
  const userData = await withAuthentication.isAuth(ctx);

  if (!siteData) {
    return {
      notFound: true,
    };
  }

  const { currentSite } = siteData;

  if (!currentSite) {
    return {
      notFound: true,
    };
  }

  const {
    siteCategories,
    key: siteKey,
    siblingSiteKey,
    organizationKey,
  } = currentSite;

  const mainCategory =
    siteCategories?.find(
      (category) => category.categoryType === CategoryTypes.Home,
    )?.categoryKey || 0;

  const recruitingCategory =
    siteCategories?.find(
      (category) => category.categoryType === CategoryTypes.Recruiting,
    )?.categoryKey || 0;

  const mainCategoryParams = {
    category: mainCategory,
    limit: 8,
  };
  const recruitingCategoryParams = {
    category: recruitingCategory,
    limit: 8,
  };
  const currentSiteParams = {
    siteKey,
  };
  const siblingSiteParams = {
    siteKey: siblingSiteKey || 0,
  };

  const sportCategoryParams: V1SitesHeadlinesForCategoryTypeDetailParams = {
    ...currentSiteParams,
    type: CategoryTypes.Sport,
    limit: 8,
  };
  const siblingSportParams: V1SitesHeadlinesForCategoryTypeDetailParams = {
    ...siblingSiteParams,
    type: CategoryTypes.Sport,
    limit: 5,
  };
  const siblingRecParams: V1SitesHeadlinesForCategoryTypeDetailParams = {
    ...siblingSiteParams,
    type: CategoryTypes.Recruiting,
    limit: 5,
  };
  const orgParams: V2OrganizationsScheduleDetailParams = {
    orgKey: organizationKey || 0,
  };
  const scheduleParams: V2OrganizationsScheduleDetailParams = {
    ...orgParams,
    // Todo, make sport configureable overall and by site
    sportKey: 1,
  };
  const limitParams = {
    ...orgParams,
    limit: 5,
  };

  try {
    const [
      featuredArticles,
      channelSports,
      channelRecruiting,
      teamSports,
      teamRecruiting,
      messageBoard,
      latestCommits,
      teamNil100,
      premiumArticles,
      teamSchedule,
    ] = await Promise.allSettled([
      v1CategoriesHeadlinesDetail2(proxyApi, mainCategoryParams),
      v1SitesHeadlinesForCategoryTypeDetail(proxyApi, sportCategoryParams),
      v1CategoriesHeadlinesDetail2(proxyApi, recruitingCategoryParams),
      v1SitesHeadlinesForCategoryTypeDetail(proxyApi, siblingSportParams),
      v1SitesHeadlinesForCategoryTypeDetail(proxyApi, siblingRecParams),
      v1SitesForumsLatestPostsDetail(proxyApi, siblingSiteKey || 0),
      rdbV2LatestList(proxyApi, limitParams),
      rdbV2Nil100List(proxyApi, limitParams),
      v1SitesPremiumHeadlinesList(proxyApi, currentSiteParams),
      v2OrganizationsScheduleDetail(proxyApi, scheduleParams),
    ]);

    const data = {
      pageType: 'home',
      siteData: siteData || [],
      userData: userData || {},
      featuredArticles: getFulfilledValue(featuredArticles),
      channelSports: getFulfilledValue(channelSports),
      channelRecruiting: getFulfilledValue(channelRecruiting),
      teamSports: getFulfilledValue(teamSports),
      teamRecruiting: getFulfilledValue(teamRecruiting),
      messageBoard: getFulfilledValue(messageBoard),
      latestCommits: getFulfilledValue(latestCommits),
      teamNil100: getFulfilledValue(teamNil100),
      premiumArticles: getFulfilledValue(premiumArticles),
      teamSchedule: getFulfilledValue(teamSchedule),
    };

    return { props: { ...data } };
  } catch (e) {
    return { props: {} };
  }
};

export default withValidUrl(Home);
